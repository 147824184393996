/* bold text*/
@font-face {
    font-family: 'GothamPro Bold';
    font-style: normal;
    font-weight: 700;
    src: url(assets/fonts/GothamPro-Bold.woff2) format('woff2'), url(assets/fonts/GothamPro-Bold.woff) format('woff');
}
/* regular text */
@font-face {
    font-family: 'GothamPro';
    font-style: normal;
    font-weight: 400;
    src: url(assets/fonts/GothamPro.woff2) format('woff2'), url(assets/fonts/GothamPro.woff) format('woff');
}

html, body {
    padding: 0;
    margin: 0;
    position: relative;
    display: block;
}

body {
    font-family: "GothamPro", sans-serif;
    font-weight: 400;
    color: #ffffff;
    overflow-x: hidden;
    background: #010000 !important;
}

a:hover, a:focus, a:active {
    color: #44c0f9 !important;
    text-decoration: none !important;
    box-shadow: none !important;
}

a {
    color: #0388c5 !important;
    text-decoration: underline !important;
}

a.btn.btn-info, button.btn.btn-info {
    background: #ffdd70;
    color: #ffffff;
    padding: 10px 25px;
    border: 0px;
    border-radius: 0px;
}

p, h1, h2, h3, h4, h5, h6 { margin: 0 0 10px 0; }
p { line-height: 1.6; }
h1, h2, h3, h4, h5, h6 { font-weight: 700; font-family: "GothamPro Bold", sans-serif; }
h1 { font-size: 36px; }
h2 { font-size: 32px; }
h3 { font-size: 28px; }
h4 { font-size: 24px; }
h5 { font-size: 20px; }
h6 { font-size: 16px; }
ul {
    padding-left: 0px;
    list-style: none;
}

img { max-width: 100%; width: 100%; }

.vertical-orientation {
    writing-mode: vertical-lr !important;
    text-orientation: mixed !important;
}

.service-button {

    position: relative;
    overflow: hidden;

    &:hover {

        box-shadow: none !important;

        &:after {
            animation: sheen 1s forwards;
            content: '';
            position: absolute;
            top: -50%;
            right: -50%;
            bottom: -50%;
            left: -50%;
            background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255, 255, 255, 0.5) 50%, rgba(229, 172, 142, 0));
            transform: rotateZ(60deg) translate(-5em, 7.5em);
        }
    }
}

.modal-step {
    span {
        color: #ffffff !important;
    }

    > div {
        > div {
            background: #035279;
        }
    }

    &[aria-disabled="false"] {
        > div {
            > div:first-child {
                background: #000000 !important;
            }
        }
    }
}

#projects{

    .project-list {

        .project {

            position: relative;
            overflow: hidden;

            a {
                z-index: 1;
                float: left;
                width: 100%;
                position: relative;
                cursor: pointer;
            }

            &:hover {

                img {
                    opacity: 0.4;
                    -webkit-transform: scale3d(1.05,1.05,1);
                    transform: scale3d(1.05,1.05,1);
                }

                .project-name {

                    opacity: 1;
                    -webkit-transform: scale3d(1,1,1);
                    transform: scale3d(1,1,1);
                    z-index: 0;

                    &:after {
                        opacity: 1;
                        -webkit-transform: rotate3d(0,0,1,45deg) scale3d(1,1,1);
                        transform: rotate3d(0,0,1,45deg) scale3d(1,1,1);
                    }

                }

            }

            img {
                opacity: 0.9;
                -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
                transition: opacity 0.35s, transform 0.35s;

            }

            .project-name {

                -webkit-transform: scale3d(0.8,0.8,1);
                transform: scale3d(0.8,0.8,1);
                padding: 0.5em 2em;
                text-transform: none;
                font-size: 0.85em;
                opacity: 0;
                position: absolute;
                top: 40%;
                left: 0px;
                width: 100%;

                &:after {

                    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
                    transition: opacity 0.35s, transform 0.35s;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 440px;
                    height: 250px;
                    border-top: 1px solid #0094d8;
                    border-bottom: 1px solid #e04e12;
                    content: '';
                    opacity: 0;
                    -webkit-transform: rotate3d(0,0,1,45deg) scale3d(1,0,1);
                    transform: rotate3d(0,0,1,45deg) scale3d(1,0,1);
                    -webkit-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;

                }

            }

        }

    }

}

.SRLStage {
    .SRLCaptionContainer {
        .SRLCaptionText {
            max-width: 397px;
        }
    }
}

.text-underline {
    position: absolute;
    left: 0px;
    bottom: 0px;
    max-height: 2px;
    overflow: hidden;
}

.modal-video-inner {
    width: 100% !important;

    .modal-video-movie-wrap {
        padding-bottom: 0px !important;
    }

    iframe {
        width: 600px !important;
        height: 500px !important;

        @media (max-width: 768px) {
            width: 350px !important;
            height: 300px !important;
        }

    }

    .modal-video-close-btn {
        display: none !important;
    }
}


@keyframes sheen {
    100% {
        transform: rotateZ(60deg) translate(1em, -9em);
    }
}